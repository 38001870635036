<template>
  <div
    :id="id"
    ref="container-element"
    :tabindex="props.submenu ? undefined : 0"
    class="modern-color-theme font-poppins max-w-lg p-1 bg-neutral-100 rounded-lg flex flex-col shadow-sm overflow-y-auto max-h-96 z-[1001] ring-1 ring-inset ring-neutral-250 outline-none"
    :class="computedLayout.class"
    :style="computedLayout.style"
    data-component-name="VContextMenu"
    @keydown.esc="emit('close')"
  >
    <ul role="menubar">
      <li v-for="(item, index) of flatItems" :ref="item.type === 'menu' ? 'submenuItems' : undefined" :key="index" role="menuitem" :data-index="item.type === 'menu' ? index : undefined">
        <template v-if="item.type === 'button' || item.type === 'checkbox' || item.type === 'menu'">
          <VSContextMenuItem :item="item" :context="props.context" v-on="getListeners(item.type === 'menu' ? index : undefined)" @close="emit('close')" />
          <VContextMenu v-if="item.type === 'menu' && !item.disabled && submenuIndex === index" :context="props.context" :position="getSubmenuPosition(index)" :controller="props.controller || containerElement" :width="props.width" :items="item.items" submenu @close="emit('close')" />
        </template>
        <template v-else-if="item.type === 'draggable'">
          <VueDraggable v-model="item.items" item-key="key">
            <template #item="{element: child}: {element: V.ContextMenu.Draggable['items'][number]}">
              <div :key="child.key">
                <VSContextMenuItem :item="child" v-on="getListeners(undefined)">
                  <VIcon size="xs" name="Outline/drag-indicator-vertical" />
                </VSContextMenuItem>
              </div>
            </template>
          </VueDraggable>
        </template>
        <template v-else-if="item.type === 'label'">
          <div
            class="min-h-9 h-9 text-sm font-medium leading-6 text-neutral-950 rounded-md flex items-center pl-3 pr-1"
            :class="{ 'justify-center': item.align === 'center' }"
            :title="item.label"
            role="heading"
            aria-level="2"
            v-on="getListeners(undefined)"
          >
            {{ item.label }}
          </div>
        </template>
        <template v-else-if="item.type === 'separator'">
          <hr>
        </template>
        <template v-else-if="item.type === 'search'">
          <VSearch v-model="item.value" :placeholder="item.placeholder" @input="submenuIndex = undefined; recomputeFlatItems()" />
        </template>
      </li>
    </ul>
  </div>
</template>
<script setup generic="T" lang="ts">
import VContextMenu from './VContextMenu.vue';
import { computed, onMounted, ref, toRef, useTemplateRef } from 'vue';
import { onClickOutsideOf } from '@component-utils/focus';
import VueDraggable from '@libs/zhyswan-vuedraggable/vuedraggable'
import { getFilteredCollection } from '@component-utils/search';
import type { V } from '@component-utils/types';
import { useManualTracking } from '@component-utils/reactivity';
import { useElementId } from '@component-utils/utils';
import VSearch from '@component-library/inputs/VSearch.vue'
import { getItemValue } from '../../utils/utils';
import VSContextMenuItem from './components/VSContextMenuItem.vue';
import VIcon from '@component-library/labels/VIcon.vue';

defineOptions({
  name: 'VContextMenu'
})

const props = withDefaults(
  defineProps<{
    items: V.ContextMenu.Item<T>[]
    position: 'absolute' | ({ [K in 'right' | 'left' | 'top' | 'bottom' | 'centerHorizontal']?: number } & { container?: { width: number, height: number } })
    context?: T
    width?: number
    /**
     * FOR INTERNAL USE ONLY
     */
    controller?: HTMLElement
    submenu?: boolean 
  }>(),
  {
    width: 250,
    context: undefined,
    controller: undefined
  }
)

const id = useElementId(undefined)

const getListeners = (value: number | undefined) => ({
  mouseenter: () => submenuIndex.value = value,
  mouseleave: () => {},
  focus: () => submenuIndex.value = value,
  blur: () => {}
})

const submenuIndex = ref<number>()

const emit = defineEmits<{
  close: []
}>()

defineExpose({
  get element () { return containerElement }
})

const items = toRef(props.items)

const { computedRef: flatItems, recompute: recomputeFlatItems } = useManualTracking(() => {
  return items.value.flatMap<V.ContextMenu.Item<T>>((item) => {
    if (item.type === 'search') {
      return [
        item,
        ...getFilteredCollection(item.items, item.value, ['label'], { fuzzy: true })
      ]
    } else if (item.type === 'sortable') {
      return item.items.toSorted((item1, item2) => +getItemValue(item2) - +getItemValue(item1))
    } else {
      return item
    }
  })
})

onMounted(() => {
  // Clear searches on mount
  for (const item of flatItems.value) {
    if (item.type === 'search') item.value = ''
  }
})

const containerElement = useTemplateRef('container-element')

onClickOutsideOf(
  [
    containerElement
  ],
  () => {
    if (props.controller) {
      // Do not close it on focus loss as it is controlled by the controller element
    } else {
      emit('close')
    }
  },
  {
    esc: true
  }
)

const windowSize = computed(() => {
  return {
    left: 0,
    top: 0,
    right: window.innerWidth,
    bottom: window.innerHeight
  }
})

const computedLayout = computed(() => {
  const menuWidth = props.width

  if (props.position === 'absolute') {
    return {
      class: 'absolute',
      style: {
        minWidth: `${menuWidth}px`,
        width: `${menuWidth}px`
      }
    }
  } else {
    const menuHeight = Math.min(384, 36 * flatItems.value.length)

    const { right: windowWidth, bottom: windowHeight } = windowSize.value

    const position: { [K in 'right' | 'left' | 'top' | 'bottom']?: string } = {}

    const { height: containerHeight, width: containerWidth } = props.position.container ?? { width: 0, height: 0 }

    if (typeof props.position.centerHorizontal === 'number') {
      const left = props.position.centerHorizontal - menuWidth / 2
      const right = props.position.centerHorizontal + menuWidth / 2

      if (left + menuWidth > windowWidth) {
        if (right - menuWidth < 0) {
          position.left = `${right}px`
        } else {
          position.right = `${windowWidth - right}px`
        }
      } else {
        position.left = `${left}px`
      }
    } else if (typeof props.position.left === 'number') {
      const left = props.position.left

      if (left + menuWidth > windowWidth) {
        position.right = `${windowWidth - left}px`
      } else {
        position.left = `${left}px`
      }
    } else if (typeof props.position.right === 'number') {
      const right = props.position.right

      if (right - menuWidth < 0) {
        position.left = `${right}px`
      } else {
        position.right = `${windowWidth - right}px`
      }
    }

    if (typeof props.position.top === 'number' && typeof props.position.bottom === 'number') {
      const top = props.position.top
      const bottom = props.position.bottom

      if (top + menuHeight > windowHeight) {
        if (bottom - menuHeight < 0) {
          position.top = `${bottom}px`
        } else {
          position.bottom = `${windowHeight - bottom}px`
        }
      } else {
        position.top = `${top}px`
      }
    } else if (typeof props.position.top === 'number') {
      const top = props.position.top

      if (top + menuHeight > windowHeight) {
        position.bottom = `${windowHeight - top + containerHeight}px`
      } else {
        position.top = `${top}px`
      }
    } else if (typeof props.position.bottom === 'number') {
      const bottom = props.position.bottom

      if (bottom - menuHeight < 0) {
        position.top = `${bottom}px`
      } else {
        position.bottom = `${windowHeight - bottom}px`
      }
    }

    return {
      class: 'fixed',
      style: Object.assign(
        {
          minWidth: `${menuWidth}px`,
          width: `${menuWidth}px`
        },
        position
      )
    }
  }
})

const submenuItems = ref<HTMLElement[]>([])

const getSubmenuPosition = (index: number) => {

  const button = submenuItems.value.find((item) => item.dataset.index === String(index))

  if (button) {
    const rect = button.getBoundingClientRect()
    const menuWidth = props.width

    const position: { [K in 'right' | 'left' | 'top' | 'bottom']?: number } = {
      top: rect.top - 4,
      bottom: rect.bottom + 4,
    }

    const { right: windowWidth } = windowSize.value

    if (rect.right + menuWidth > windowWidth) {
      position.right = rect.left
    } else {
      position.left = rect.right
    }

    return position
  } else {
    throw new Error('Submenu position not available')
  }
}
</script>
