<template>
  <component
    :is="tagName"
    class="min-h-9 h-9 text-sm flex items-center px-3 gap-3 w-full whitespace-nowrap"
    :disabled="props.item.disabled"
    :class="computedWrapperClass"
    v-bind="computedAttributes"
    data-component-name="VSContextMenuItem"
    v-on="computedListeners"
  >
    <slot />
    <VIcon v-if="props.item.icon" :name="props.item.icon" :class="{ 'opacity-50' : isCheckbox && isDisabled }" />
    <div class="overflow-ellipsis overflow-hidden" :class="{ 'opacity-50' : isCheckbox && isDisabled }">
      {{ props.item.label }}
    </div>
    <input
      v-if="isCheckbox"
      :checked="getItemValue(props.item as V.ContextMenu.Checkbox)"
      type="checkbox"
      :disabled="isDisabled"
      class="h-4 w-4 rounded border-neutral-300 focus:ring-blue-500 text-primary-500 disabled:text-neutral-200 ml-auto pointer-events-none"
      :tabindex="-1"
      @click="onCheckboxClick"
    >
    <VIcon v-if="props.item.type === 'menu'" class="ml-auto" name="Solid/chevron-right" />
  </component>
</template>
 
<script setup generic="T" lang="ts">
import { computed } from 'vue';
import type { V } from '@component-utils/types';
import VIcon from '@component-library/labels/VIcon.vue';
import { getItemValue } from '@component-utils/utils';

defineOptions({
  name: 'VSContextMenuItem'
})

const props = defineProps<{
  item: V.ContextMenu.Button<T> | V.ContextMenu.Checkbox | V.ContextMenu.Menu<T>
  context?: T
}>()

const emit = defineEmits<{
  close: []
}>()

const tagName = computed(() => {
  switch (props.item.type) {
    case 'button': return 'href' in props.item ? 'a' : 'button'
    case 'checkbox': return 'label'
    default: return 'button'
  }
})

const isCheckbox = computed(() => props.item.type === 'checkbox')
const isDisabled = computed(() => props.item.disabled)

const computedAttributes = computed(() => {
  switch (props.item.type) {
    case 'button': {
      return {
        type: 'button',
        title: props.item.title ?? props.item.label,
        href: 'href' in props.item ? props.item.href : undefined
      }
    }
    case 'checkbox': {
      return {
        title: props.item.label,
        tabindex: props.item.disabled ? undefined : 0
      }
    }
    default: {
      return {
        title: props.item.label
      }
    }
  }
})

const onCheckboxClick = () => {
  if (isDisabled.value) return

  const item = props.item as V.ContextMenu.Checkbox

  const value = getItemValue(item)
  if (typeof item.value === 'boolean') {
    item.value = !value
  } else {
    item.value.set(!value)
  }
}

const computedListeners = computed(() => {
  const item = props.item

  if (item.type === 'button' && !('href' in item)) {
    return {
      click () {
        item.action(props.context as T)

        emit('close')
      }
    }
  } else if (item.type === 'checkbox') {
    return {
      keydown (event: KeyboardEvent) {
        if (event.code !== 'Space' && event.code !== 'Enter') return

        onCheckboxClick()
      }
    }
  } else {
    return {}
  }
})

const computedWrapperClass = computed (() => {
  return {
    'cursor-not-allowed text-neutral-400': isDisabled.value, 
    'hover:bg-neutral-150 text-neutral-950': !isDisabled.value,
    'cursor-not-allowed': isCheckbox.value && isDisabled.value, 
    'cursor-pointer': isCheckbox.value && !isDisabled.value,
    'pr-2': props.item.type === 'menu'
  }
})
</script>
