import type { App, Component, Reactive } from "vue"
import { createVueApp } from "~/features/vue"
import type { ComponentEmits, ComponentProps, V } from "./types"
import VSimpleDialog from "@component-library/dialogs/VSimpleDialog.vue"
import VContextMenu from "@component-library/dialogs/VContextMenu.vue"
import { stopAndPrevent } from "~/features/_abstract/utils/event"

type OnCloseEmitParameters<C extends Component> = Parameters<NonNullable<ComponentEmits<C>['onClose']>>

const V_DIALOG_STATE: {
  __container?: HTMLElement

  // Chaining
  promise: Promise<void>

  // HTML container element
  get container(): HTMLElement
} = {
  promise: Promise.resolve(),

  get container() {
    return this.__container ??= (() => {
      let element = document.getElementById('v-dialog-container')
      if (element) return element
  
      element = document.createElement('div')
      element.id = 'v-dialog-container'
  
      // TODO: Temporary workaround for weirdness in the app. Remove when not necessary
      element.setAttribute('class', 'relative z-[1000]')
  
      document.body.appendChild(element)
  
      return element
    })()
  }
}

type DialogInstance = {
  _closed?: boolean
  _app?: App<Element>
  _element?: Element
  _resolve?: () => void

  setup: () => Promise<void>
  close: (resolve?: boolean) => void
}

function createDialogInstance<C extends Component, CProps extends ComponentProps<C>, CloseCallback extends (...params: OnCloseEmitParameters<C>) => void>(
  component: C,
  props: CProps,
  options: {
    callback?: CloseCallback
    immediate?: boolean
    injects?: Record<string, unknown>
  }
): DialogInstance {
  const state: DialogInstance = {
    _closed: false,
    setup () {
      return new Promise<void>((resolve) => {
        if (state._closed) return resolve()

        state._resolve = resolve
        state._element = document.createElement('div')
        state._app = createVueApp(component, Object.assign(props, {
          onClose: (...params: Parameters<CloseCallback>) => {
            state.close(false)

            options.callback?.(...params)

            resolve()
          }
        }))
        
        if (options.injects) {
          Object.entries(options.injects).forEach(([key, value]) => {
            state._app!.provide(key, value)
          })
        }
    
        state._app.mount(state._element)
      
        V_DIALOG_STATE.container.appendChild(state._element)
      })
    },
    close (resolve = true) {
      this._app?.unmount()
      this._element?.remove()

      this._closed = true

      if (resolve) {
        this._resolve?.()
      }
    }
  }

  return state
}

/**
 * Function that queues and displays dialogs on the page
 *
 * @template C - The type of the Vue component
 * @template CProps - The type of the props for the Vue component
 * @template CloseCallback - The type of the callback function for the dialog close event
 *
 * @param {C} component - A Vue 3 component that uses VDialog component and implements the 'close' emit
 * @param {CProps} [props=Object.create(null)] - Props that will be forwarded to the dialog component when mounted
 * @param {Object} [options={}] - Options object that modify the behavior or the consequences of the dialog
 * @param {CloseCallback} [options.callback] - A callback function that will be called when the dialog closes
 * @param {boolean} [options.immediate=false] - If true, the dialog is displayed immediately
 */
export function useDialog<C extends Component, CProps extends ComponentProps<C>, CloseCallback extends (...params: OnCloseEmitParameters<C>) => void>(
  component: C,
  props: CProps,
  options: {
    callback?: CloseCallback
    immediate?: boolean
    injects?: Record<string, unknown>
  } = {}
) {
  const state = createDialogInstance(component, props, options)

  if (options.immediate) {
    void state.setup()
  } else {
    V_DIALOG_STATE.promise = V_DIALOG_STATE.promise.then(() => state.setup())
  }

  return state as { close: () => void }
}

export function useSimpleDialog<
  CProps extends ComponentProps<typeof VSimpleDialog>,
  CloseCallback extends (...params: OnCloseEmitParameters<typeof VSimpleDialog>) => void
>(
  props: CProps,
  options: {
    callback?: CloseCallback
    onAccept?: () => void
    onReject?: () => void
    immediate?: boolean
    injects?: Record<string, unknown>
  } = {}
) {
  return useDialog(
    VSimpleDialog,
    props,
    {
      ...options,
      callback: (decision) => {
        options.callback?.(decision)

        if (decision) {
          options.onAccept?.()
        } else {
          options.onReject?.()
        }
      }
    }
  )
}

export function useContextMenu<T> (event: MouseEvent, items: Reactive<V.ContextMenu.Item<T>[]>, context: T) {
  return new Promise<void>((resolve) => {
    const element = document.createElement('div')

    const instance = createVueApp(VContextMenu as Component, {
      items,
      context,
      position: {
        left: event.x,
        top: event.y
      },
      onClose: () => {
        instance.unmount()
        element.remove()

        resolve()
      }
    })
    
    instance.mount(element)
  
    V_DIALOG_STATE.container.appendChild(element)
  })
}

type UseFilePickerProps = {
  accept: string | string[]
  callback: (...files: File[]) => void
  // Optionals
  multiple?: boolean
}

export function useFilePicker (props: UseFilePickerProps) {
  const input = document.createElement('input')
  input.type = 'file'
  input.multiple = props.multiple || false
  input.accept = typeof props.accept === 'string' ? props.accept : props.accept.join(',')
  input.setAttribute('class', 'hidden')

  document.body.appendChild(input)

  input.addEventListener('change', (event) => {
    stopAndPrevent(event)
  
    const target = event.target as HTMLInputElement
    const files = Array.from(target.files as FileList)
  
    props.callback(...files)
  }, { once: true })

  input.click()
  input.remove()
}