import { getUUID } from "@component-utils/utils"
import { type Directive } from "vue"

function parse (string: string | null) {
  return string?.split(' ') ?? []
}

function strigify (array: string[]) {
  return array.join(' ')
}

const TargetToComponent = {
  dialog: 'VDialog'
} as const

const TypeToAttribute = {
  title: 'aria-labelledby',
  description: 'aria-describedby'
} as const

function getTarget (element: Element, target: keyof typeof TargetToComponent) {
  return element.closest(`[data-component-name="${TargetToComponent[target]}"]`)
}

function getTargetAndAttribute (element: Element, targetWithType: `${keyof typeof TargetToComponent}:${keyof typeof TypeToAttribute}`) {
  const [target, type] = targetWithType.split(':', 2) as [keyof typeof TargetToComponent, keyof typeof TypeToAttribute]

  return [
    getTarget(element, target),
    TypeToAttribute[type]
  ] satisfies [
    Element | null,
    typeof TypeToAttribute[keyof typeof TypeToAttribute]
  ]
}

/**
 * Vue3 directive that appends element's ID to the closest specific ancestors aria field
 */
export const vA11y: Directive<HTMLElement, never, string, `${keyof typeof TargetToComponent}:${keyof typeof TypeToAttribute}`> = {
  mounted (element, binding) {
    if (!binding.arg) return

    const [target, attribute] = getTargetAndAttribute(element, binding.arg)
    if (!target) return

    const list = parse(target.getAttribute(attribute))

    list.push(element.id ||= getUUID())

    target.setAttribute(attribute, strigify(list))
  },
  unmounted (element, binding) {
    if (!binding.arg) return

    const [target, attribute] = getTargetAndAttribute(element, binding.arg)
    if (!target || !element.id) return

    const list = parse(target.getAttribute(attribute))

    list.splice(list.indexOf(element.id), 1)

    target.setAttribute(attribute, strigify(list))
  }
}