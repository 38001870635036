<template>
  <VBackdrop v-if="loaded" @click="props.closeViaBackdrop && emit('close')">
    <div
      role="dialog"
      aria-modal="true"
      class="modern-color-theme font-poppins p-5 bg-white rounded-2xl gap-6 flex flex-col shadow-modal"
      :class="{ 'relative': props.closeViaButton }"
      :style="computedLayout"
      v-bind="$attrs"
      data-component-name="VDialog"
      @click.stop
    >
      <VButton v-if="props.closeViaButton" variant="text" color="secondary" icon :container-props="{ class: 'absolute top-2 right-2' }" @click="emit('close')">
        <VIcon name="Solid/close" />
      </VButton>
      <div v-if="props.icon || slots.title || props.title" class="flex flex-col items-center text-center gap-4">
        <VIcon v-if="props.icon" color="neutral/650" :name="props.icon" />
        <slot name="title">
          <VHeading v-if="props.title" v-a11y:dialog:title color="neutral/600" level="5" medium>
            {{ props.title }}
          </VHeading>
        </slot>
      </div>
      <div class="flex flex-col gap-4 h-full overflow-y-hidden">
        <slot />
      </div>
      <div v-if="slots.buttons" class="flex justify-end gap-4">
        <slot name="buttons" />
      </div>
    </div>
  </VBackdrop>
</template>
<script setup lang="ts">
import VButton from '@component-library/buttons/VButton.vue';
import VBackdrop from '../layouts/VBackdrop.vue'
import VIcon from '@component-library/labels/VIcon.vue';
import { computed, onBeforeMount, onMounted, ref } from 'vue';
import { useLoader } from '@component-utils/loader';
import { useErrorToast } from '@component-utils/toasts';
import type { Icon } from '@icons/index';
import VHeading from '@component-library/labels/VHeading.vue';
import { vA11y } from '@component-library/directives';

defineOptions({
  name: 'VDialog',
  inheritAttrs: false
})

const props = withDefaults(
  defineProps<{
    width?: string
    height?: string
    minHeight?: string
    closeViaButton?: boolean
    closeViaBackdrop?: boolean
    load?: () => Promise<void>
    loadIcon?: Icon
    loadMessage?: string
    title?: string
    icon?: Icon
  }>(),
  {
    width: '500px',
    height: undefined,
    minHeight: undefined,
    closeViaBackdrop: false,
    closeViaButton: false,
    load: undefined,
    loadIcon: undefined,
    loadMessage: undefined,
    title: undefined,
    icon: undefined
  }
)

const emit = defineEmits<{
  close: []
}>()

const slots = defineSlots<{
  title?(): any
  default(): any
  buttons?(): any
}>()

const computedLayout = computed(() => {
  return {
    minWidth: props.width,
    width: props.width,
    minHeight: props.minHeight ?? props.height,
    height: props.height
  }
})

const loaded = ref(!props.load)

onBeforeMount(() => {
  // Click on the body so all dropdowns and context menus are closed as we cannot guarantee no z-index issues with current codebase
  document.body.click()
})

onMounted(async () => {
  if (props.load) {
    const loader = useLoader()
    try {
      loader.start({ opaque: false, message: props.loadMessage, icon: props.loadIcon })

      await props.load()

      loaded.value = true
    } catch (e) {
      useErrorToast(e)

      emit('close')
    } finally {
      loader.stop()
    }
  }
})
</script>
